@import "antd/dist/reset.css";
@font-face {
  font-family: "ProjectFont";
  src: url(./components/Fonts/Cairo.woff);
}

body, body * {
  font-family: 'ProjectFont' !important;
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.ant-select-tree .ant-select-tree-node-content-wrapper:hover {
  background-color: #d9f3ff;
}

div[aria-readonly="true"] > span {
  color: rgb(184, 184, 184);
  font-weight: 700;
}

.selectCellClassname {
  display: flex;
  align-items: center;
  justify-content: center;
  > input {
    margin: 0;
  }
}

.treeStype {
  background-color: #f9f9f9;
  border: 0px;
}

.mainRow {
  .rdg-cell {
    background-color: #bddbf146;
    font-size: 15px;
    font-weight: bold;
  }
}

.inRow {
  .rdg-cell {
    color: #11a732;
  }
}

.outRow {
  .rdg-cell {
    color: #c71d1d;
  }
}

.prevBalanceRow {
  .rdg-cell {
    color: #17a196;
  }
}

.difBalanceRow {
  .rdg-cell {
    color: tomato;
    font-size: 15px;
  }
}

.tremBalanceRow {
  .rdg-cell {
    color: #008080;
  }
}

.child {
  opacity: 0.8;
  position: absolute;
  top: 24px;
  right: 276px;
}

.finalBalanceRow {
  .rdg-cell {
    color: #1d7ac7;
    font-size: 15px;
  }
}

.transRow {
  font-size: 13.5px;
}

.childRow {
  font-weight: bold;
}

.unSaveRow {
  .rdg-cell {
    background-color: #fff5f5;
  }
}

.r104f42s700-beta13 {
  color: #5c5c5c;
}
.mainCardsContent {
  background-color: "#fff";
  height: 72.5vh;
}

.warpCardsContent {
  max-height: 70vh;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
}

.cardPatt {
  width: 49.6%;
  overflow: auto;
  margin: 2px;
}
.card-patt-fill {
  width: 100%;
  overflow: auto;
  margin: 2px;
}

.stripe {
  color: white;
  background: repeating-linear-gradient(
    45deg,
    #fafafa,
    #fafafa 10px,
    #ffffff 10px,
    #ffffff 20px
  );
}

.ant-card-small
  > .ant-card-head
  > .ant-card-head-wrapper
  > .ant-card-head-title {
  padding: 6px 0;
}

.cardFitContent {
  min-height: fit-content;
  height: fit-content;
}

.steps-content {
  min-height: 200px;
  margin-top: 16px;
  padding-top: 80px;
  text-align: center;
  background-color: #fafafa;
  border: 1px dashed #e9e9e9;
  border-radius: 2px;
}

.steps-action {
  margin-top: 24px;
}

[data-theme="dark"] .steps-content {
  margin-top: 16px;
  border: 1px dashed #303030;
  background-color: rgba(255, 255, 255, 0.04);
  color: rgba(255, 255, 255, 0.65);
  padding-top: 80px;
}

.logo {
  text-align: center;
  width: 120px;
  height: 27px;
  margin: 3px;
  background: rgba(255, 255, 255, 0.2);
}

.ant-select.ant-select-multiple .ant-select-selection-item-content {
  font-size: 12px;
}

.ant-pro-top-nav-header-logo img {
  display: inline-block;
  height: 32px;
  vertical-align: middle;
}

.ant-popover-inner-content {
  padding: 10px;
  color: rgba(0, 0, 0, 0.85);
}

.ant-menu-submenu-popup > .ant-menu {
  background-color: #212e38;
  color: rgb(255 255 255 / 65%);
}

.ant-layout-header {
  height: 50px;
  line-height: 50px;
  padding: 0 20px;
  background-color: #fff;
}

.ant-drawer-title{
  text-align: end;
}

.trigger {
  line-height: 50px;
}



.ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
.ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
  padding: 10 7px;
  border-radius: 6px;
}

.site-card-border-less-wrapper {
  background: #ececec;
  padding: 30px;
}

.buttonForms {
  margin: 10px;
  width: 100px;
}

col.ant-col-2.ant-form-item-label {
  min-width: 100px;
}

aside.ant-layout-sider.ant-layout-sider-dark {
  flex: 0 0 256px;
  max-width: 256px;
  min-width: 256px;
  width: 256px;
}

.rtl-avatar-uploader > .ant-upload {
  max-height: 100px;
  margin-right: 12px !important;
}

.ltr-avatar-uploader > .ant-upload {
  max-height: 100px;
  margin-left: 20px !important;
}

.heroImageprv {
  max-height: 100px;
  max-width: 100%;
}

.ant-menu-rtl.ant-menu-inline,
.ant-menu-rtl.ant-menu-vertical {
  border-right: none;
  border-left: none;
}




.area {
  background: #ffffff;
  background: -webkit-linear-gradient(to left, #8f94fb, #ffffff);
  width: 100%;
  height: 100vh;
  z-index: 1;
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: #0777d3;
  animation: animate 25s linear infinite;
  bottom: -150px;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}

.lang-text-left {
  text-align: left;
  margin-right: 7px;
}

.lang-text-right {
  text-align: right;
  margin-right: 7px;
}

/********************************************************************| Back Top |***************************************************************************/

.backTop {
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 4px;
  background-color: #212e38;
  color: #fff;
  font-size: 18px;
  text-align: center;
}

.ant-back-top-rtl {
  left: 30px;
}

.ant-back-top {
  right: 30px;
}

/********************************************************************| Drawer Mobile |***************************************************************************/


.ant-menu-dark.ant-menu-submenu>.ant-menu {
  background-color: #212e38;
}

.ant-menu-dark {
  color: rgba(255, 255, 255, 0.65);
  background: #212e38;
}

.ant-switch-checked-InActive {
  background-color: #f44336;
}


.bgTab {
  padding: 24px;
  border-radius: 8px;
  margin-bottom: 15px;
  background: #fff;
}

.SPINexa {
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
  margin: 20px 0;
}

.bg {
  max-width: 900px;
  /* margin: 0 auto; */
}

.bg {
  padding: 24px;
  border-radius: 5px;
  margin-bottom: 15px;
  background: #fff;
}

.dateTimeStyle {
  width: 100%;
}

.nextPrev {
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 auto;
}

.ant-menu-item-only-child {
  padding-left: 20px;
}

body::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

body::-webkit-scrollbar-thumb {
  background-color: #d7d7d7;
  border-radius: 20px;
  border: 3px solid #f1f1f1;
}

.scrollCard {
  max-height: 300px;
  overflow: auto;
}

.scrollCard::-webkit-scrollbar {
  width: 12px;
}

.scrollCard::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scrollCard::-webkit-scrollbar-thumb {
  background-color: #d7d7d7;
  border-radius: 20px;
  border: 3px solid #f1f1f1;
}

.bgManufact {
  max-width: 1300px;
  /* margin: 0 auto; */
  padding: 5px 24px 0px 24px;
  border-radius: 5px;
  margin-bottom: 8px;
  background: #fff;
}

.miniCardManufact {
  max-width: 1300px;
  /* margin: 0 auto; */
  padding: 5px 24px 0px 24px;
  margin-bottom: 0px;
  background: #fff;
}

.bgManufactActions {
  max-width: 1300px;
  margin: 0 auto;
  padding: 24px 24px 0px 24px;
  border-radius: 5px;
  margin-bottom: 8px;
  background: #fff;
}

.virsionDiv {
  position: absolute;
  bottom: 0px;
  width: 100%;
  background-color: #000f1d;
  color: #ececec;
  padding: 0px;
  text-align: center;
}

.virsionText {
  margin-top: 15px;
}

.ant-select-tree-list > div {
  width: 600px;
}

.eyeTrue {
  font-size: 16px;
  color: #78c6f3;
}

.eyeFalse {
  font-size: 16px;
  color: #f38989;
}

.inpCode {
  font-weight: bold;
  margin-right: -5px;
  background-color: #fff !important;
}

.formImportFormXls {
  padding: 5px;
  border-radius: 10px;
  max-width: 100%;
  margin-bottom: 3px;
  background: #fff;
}

.rdg-cell:has(> .numCell) {
  text-align: right;
}

/* .ant-alert {
  padding: 0px 15px;
  line-height: 0.6;
  font-size: 10px;
} */

.rdg-cell:has(> .keyNum) {
  padding: 0 2px;
  display: inline-flex;
}

.frHeader {
  max-width: 100%;
  padding: 2px 24px 2px 24px;
  border-radius: 10px 10px 0px 0px;
  margin-bottom: 3px;
  background: #fff;
}





.frlogoImage {
  padding: 27px 24px 27px 24px;
  text-align: center;
  max-width: 100%;
  margin-bottom: 3px;
  background: #fff;
}

.ant-form-horizontal .ant-form-item-control {
  flex: 1 1;
  width: 10%;
}

.cellNumber {
  text-align: right;
  background-position: 50%;
  background-size: 100%;
  block-size: 28px;
  display: inline-block;
  inline-size: 100%;
}

.frContent {
  padding: 5px 24px 5px 24px;
  border-radius: 5px;
  max-width: 100%;
  margin-bottom: 3px;
  background: #fff;
}

.ant-table-thead{
  visibility: collapse;
}

/* smartphones, portrait iPhone, portrait 480x320 phones (Android) */ 
@media (min-width:280px){ 
  .inOutButton{
  padding: 4px 25px;
  width: 37vw;
  height: 120px;
  font-size:18px;
}

.mapLocation{
  width: 220px;
  height: 190px;
  margin-bottom: 0px;
  border: 1px solid #e9e9e9;
  border-radius: 7px;
  position: relative;
}
.cardHome {
  width: 46.7vw;
  border-radius: 6px;
  text-align: center;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03),
    0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02);
}
}

/* smartphones, portrait iPhone, portrait 480x320 phones (Android) */ 
@media (min-width:320px){ 
  .inOutButton{
  padding: 4px 25px;
  width: 41vw;
  height: 120px;
  font-size:24px;
}

.mapLocation{
  width: 84vw;
  height: 72vw;
  margin-bottom: 0px;
  border: 1px solid #e9e9e9;
  border-radius: 7px;
  position: relative;
}

.cardHome {
  width: 47vw;
  border-radius: 6px;
  text-align: center;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03),
    0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02);
}
}

/* smartphones, portrait iPhone, 6/7/8 */ 
@media (min-width:375px){ 
  .inOutButton{
  padding: 4px 25px;
  width: 41vw;
  height: 120px;
  font-size:24px;
}

.mapLocation{
  width: 84vw;
  height: 72vw;
  margin-bottom: 0px;
  border: 1px solid #e9e9e9;
  border-radius: 7px;
  position: relative;
}

.cardHome {
  width: 47.5vw;
  border-radius: 6px;
  text-align: center;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03),
    0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02);
}
}

/* smartphones, portrait iPhone, 6/7/8 Plus */ 
@media (min-width:414px){ 
  .inOutButton{
  padding: 4px 25px;
  width: 41vw;
  height: 120px;
  font-size:24px;
}

.mapLocation{
  width: 84vw;
  height: 72vw;
  margin-bottom: 0px;
  border: 1px solid #e9e9e9;
  border-radius: 7px;
  position: relative;
}

.cardHome {
  width: 47.5vw;
  border-radius: 6px;
  text-align: center;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03),
    0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02);
}
}

/* smartphones, Android phones, landscape iPhone */
@media (min-width:480px){ 
  .inOutButton{
    padding: 4px 25px;
    width: 37vw;
    height: 120px;
    font-size:24px;
  }

  .mapLocation{
    width: 76vw;
    height: 67vw;
    margin-bottom: 0px;
    border: 1px solid #e9e9e9;
    border-radius: 7px;
    position: relative;
  }

  .cardHome {
    width: 31.5vw;
    border-radius: 6px;
    text-align: center;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03),
      0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02);
  }
 }

/* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */ 
@media (min-width:600px){
  .inOutButton{
    padding: 4px 25px;
    width: 175px;
    height: 120px;
    font-size:24px;
  }
  .mapLocation{
    width: 365px;
    height: 315px;
    margin-bottom: 0px;
    border: 1px solid #e9e9e9;
    border-radius: 7px;
    position: relative;
  }
  .cardHome {
    width: 23.58vw;
    border-radius: 6px;
    text-align: center;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03),
      0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02);
  }
 }

/* tablet, landscape iPad, lo-res laptops ands desktops */
@media (min-width:801px){ 
  .inOutButton{
    padding: 4px 25px;
    width: 175px;
    height: 120px;
    font-size:24px;
  }
  .mapLocation{
    width: 365px;
    height: 315px;
    margin-bottom: 0px;
    border: 1px solid #e9e9e9;
    border-radius: 7px;
    position: relative;
  }
  .cardHome {
    width: 18.95vw;
    border-radius: 6px;
    text-align: center;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03),
      0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02);
  }
 }

/* big landscape tablets, laptops, and desktops */
@media (min-width:1025px){  
  .inOutButton{
    padding: 4px 25px;
    width: 175px;
    height: 120px;
    font-size:24px;
  }
  .mapLocation{
    width: 365px;
    height: 315px;
    margin-bottom: 0px;
    border: 1px solid #e9e9e9;
    border-radius: 7px;
    position: relative;
  }
  .cardHome {
    width: 13.47vw;
    border-radius: 6px;
    text-align: center;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03),
      0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02);
  }
}

/* hi-res laptops and desktops */
@media (min-width:1281px){ 
  .inOutButton{
    padding: 4px 25px;
    width: 175px;
    height: 120px;
    font-size:24px;
  }
  .mapLocation{
    width: 365px;
    height: 315px;
    margin-bottom: 0px;
    border: 1px solid #e9e9e9;
    border-radius: 7px;
    position: relative;
  }
  .cardHome {
    width: 150px;
    border-radius: 6px;
    text-align: center;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03),
      0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02);
  }
}
 
.contentList {
  padding: 5px 0px;
  max-width: 100%;
  margin-bottom: 3px;
}

.contentScrolling {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 86vh,
}

/* @media screen and (max-width: 768px) and (min-width: 414px) {
.cardHome {
  width: 100px;
  border-radius: 6px;
  text-align: center;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03),
    0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02);
}
}
@media screen and (min-device-width: 481px) and (max-device-width: 1024px){
.cardHome {
  width: 160px;
  border-radius: 6px;
  text-align: center;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03),
    0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02);
}
} */

:where(.css-dev-only-do-not-override-14jj66p).ant-table-wrapper .ant-table.ant-table-small .ant-table-title, :where(.css-dev-only-do-not-override-14jj66p).ant-table-wrapper .ant-table.ant-table-small .ant-table-footer, :where(.css-dev-only-do-not-override-14jj66p).ant-table-wrapper .ant-table.ant-table-small .ant-table-thead>tr>th, :where(.css-dev-only-do-not-override-14jj66p).ant-table-wrapper .ant-table.ant-table-small .ant-table-tbody>tr>th, :where(.css-dev-only-do-not-override-14jj66p).ant-table-wrapper .ant-table.ant-table-small .ant-table-tbody>tr>td, :where(.css-dev-only-do-not-override-14jj66p).ant-table-wrapper .ant-table.ant-table-small tfoot>tr>th, :where(.css-dev-only-do-not-override-14jj66p).ant-table-wrapper .ant-table.ant-table-small tfoot>tr>td {
  padding: 0px 3px;
}


.ant-menu-rtl{
  direction:rtl;
}

element.style {
  grid-column-start: 4;
}
:where(.css-dev-only-do-not-override-14jj66p).ant-layout, :where(.css-dev-only-do-not-override-14jj66p).ant-layout * {
  box-sizing: border-box;
}
body, body * {
  font-family: 'ProjectFont' !important;
}
*, *::before, *::after {
  box-sizing: border-box;
}
.r104f42s7-0-0-beta-30 *, .r104f42s7-0-0-beta-30 *::before, .r104f42s7-0-0-beta-30 *::after {
  box-sizing: inherit;
}
.c1wupbe7-0-0-beta-30[aria-selected='true'] {
  outline: 2px solid #33cbcc;
  outline-offset: -2px;
}
.frTabX {
  padding: 20px 24px 20px 24px;
  max-width: 100%;
  margin-bottom: 3px;
  background: #fff;
}

.ant-row .ant-row-rtl {
  margin-bottom: 0px;
}

.ant-form-item-explain,
.ant-form-item-extra {
  font-size: 8px;
  line-height: 0;
  margin-bottom: -25px;
  visibility: hidden;
}

.ant-form-item {
  margin-bottom: 0;
}

.bgHeaderEmp {
  margin: 0 auto;
  padding: 5px 24px 5px 24px;
  border-radius: 10px 0px 0px 0px;
  margin-bottom: 3px;
  background: #fff;
}


.frFooterEmp {
  max-width: 100%;
  padding: 2px 20px 2px 20px;
  border-radius: 0px 0px 0px 10px;
  background: #fff;
}

.frFooter {
  max-width: 100%;
  padding: 2px 20px 2px 20px;
  border-radius: 0px 0px 10px 10px;
  background: #fff;
}


.bgHeader {
  margin: 0 auto;
  padding: 5px 24px 5px 24px;
  border-radius: 10px 10px 0px 0px;
  margin-bottom: 3px;
  background: #fff;
}

.bgTabX {
  padding: 10px;
  background: #fff;
}

.tableContener_middle {
  padding: 5px 10px 10px 10px;
  border-radius: 10px 10px 0px 0px;
  background: #fff;
}

.tableContener_footer {
  margin: 0 auto;
  padding: 5px 24px 5px 24px;
  border-radius: 0px 0px 10px 10px;
  margin-top: 3px;
  background: #fff;
}

.actionButtons {
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 5px;
  text-align: start;
}

.tableButtons {
  padding-left: 10px;
  padding-right: 10px;
  text-align: end;
}

.formItemClass .ant-form-item-control-input {
  min-height: 0px !important;
}

.addNewButton {
  width: 150px;
  border-radius: 5px;
}

.bgFooter {
  margin: 0 auto;
  padding: 20px 24px 5px 24px;
  border-radius: 0px 0px 10px 10px;
  margin-top: 3px;
  background: #fff;
}

.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab {
  margin: 3;
}

.table-row-light {
  background-color: #ffffff;
}

.table-row-dark {
  background-color: #f9f9f9;
}

.ant-collapse-icon-position-left > .ant-collapse-item > .ant-collapse-header {
  padding: 5px 35px;
  background: white;
}

.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
  padding: 5px 35px;
  background: white;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 0px;
}

.ant-collapse {
  background-color: #ffffff00;
}

.gen-input .ant-form-item-control-input {
  min-height: 20px;
}

.active-row {
  background-color: #b6b6b6;
  font-weight: 600;
}

.treeMain-row {
  background-color: #f5fdff;
  color: #0354a0;
}

.treeDetails-row {
  background-color: rgb(250, 247, 233);
  color: rgb(99, 79, 0);
}

.inStock-active-row {
  color: #23537a;
  font-weight: 600;
}

.firstActive-row {
  background-color: #e0e0e0;
  font-weight: 500;
}

.master-row {
  background-color: #ffffff;
}

.detailIn-row {
  background-color: #fff;
  color: #11a732;
  font-weight: 500;
}

.detailOut-row {
  background-color: #fff;
  color: #bd1873;
  font-weight: 500;
}

.react-resizable {
  position: relative;
  background-clip: padding-box;
}

.react-resizable-handle {
  position: absolute;
  width: 10px;
  height: 100%;
  bottom: 0;
  right: -5px;
  cursor: col-resize;
  z-index: 1;
}

div.scroll {
  margin: 4px, 4px;
  padding: 4px;

  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.resizable-rtl-handle {
  right: auto;
  left: -5px;
}



.ant-cascader-dropdown-rtl {
  align-items: flex-end !important;
  direction: rtl !important;
  flex-direction: row-reverse !important;
}

.ant-input[disabled] {
  color: rgb(0 0 0 / 50%);
}

.ant-switch-checked {
  background-color: #d9363e;
}

.ant-popover-inner-content {
  padding-bottom: 1px;
}

.ak-divider {
  margin: 0px;
}

.site-layout-background {
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 10px;
  min-height: 280px;
}

.breadcrumbStyle {
  margin-bottom: 5px;
  margin-right: 25px;
  margin-left: 25px;
}

.tbHeader {
  color: rgb(99, 99, 99);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.ant-form input[type="checkbox"]:focus {
  outline: none;
  outline: 0px auto;
  outline-offset: 0px;
}

.drag-handle {
  display: none;
}

.h197vzie700-beta11 {
  color: #7e7e7e;
  background-color: #ffffff;
}

.cellExpandClassname {
  float: left;
  display: table;
  block-size: 100%;
}

.childRowActionCrossClassname {
  &::before,
  &::after {
    content: "";
    position: absolute;
    background: rgb(197, 197, 197);
  }

  &::before {
    inset-inline-start: 15.5px;
    inline-size: 1px;
    block-size: 100%;
  }
}

.span {
  display: table-cell;
  vertical-align: middle;
  cursor: pointer;
}

.r104f42s700-beta13 {
  --rdg-checkbox-color: #20c77c;
  --rdg-checkbox-focus-color: #20c77c;
  --rdg-selection-color: #90cfb5;
}

.rdg-cell{
  inset-inline-start: var(--rdg-frozen-left) !important;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track {
  background-color: #ececec;
}
::-webkit-scrollbar-thumb {
  background-color: #d7d7d7;
  border-radius: 8px;
}

.ant-message .ant-message-notice .ant-message-success>.anticon {
  color: #52c41a;
  margin-bottom: 3px;
}

.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #f8fcff;
}
.login-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  max-width: 1000px;
  background-color: white;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.16);
  overflow: hidden;
  margin: 0 auto;
  border-radius: 12px;
}
#login-form {
  flex: 1 0 100%;
  max-width: 480px;
  width: 100%;
  padding: 60px;
}
#login-form p {
  margin-bottom: 30px;
}

#login-form p.form-title {
  color: #727272;
  font-size: 42px;
  font-weight: bold;
  line-height: 1.4; 
  margin: 15px 5px
}

#login-form .ant-form-item-label > label.ant-form-item-required::before {
  display: none;
}

#login-form .ant-input-affix-wrapper {
  padding: 5px 15px;
}
#login-form_username {
  height: 48px;
}
#login-form .ant-btn {
  height: 42px;
  letter-spacing: 1px;
  border-radius: 6px;
}
.login-form-button {
  width: 100%;
}




.childSpin {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 62%;
  left: 50%;
  margin: -50px 0 0 -50px;
}



@media screen and (min-width: 320px) {
  .extra {
    font-size: 12px;
  }
  .title{
    font-size: 12px;
  }
  .spaceRes{
    gap:3px !important
    }

}
@media screen and (min-width: 601px) {
  .extra {
    font-size: 12px;
  }
  .title{
    font-size: 12px;
  }

}

@media screen and (min-width: 800px) {
  .extra {
    font-size: 14px;
  }
  .title{
    font-size: 16px;
  }

}

.illustration-wrapper {
  display: flex;
  align-items: flex-end;
  max-width: 800px;
  min-height: 100%;
  background-color: #fffdf2;
}
.illustration-wrapper img {
  display: block;
  width: 100%;
}
@media screen and (max-width: 1023px) {
  .login-box {
    flex-direction: column;
    box-shadow: none;
  }
  .illustration-wrapper {
    max-width: 100%;
    min-height: auto;
  }
  #login-form {
    max-width: 100%;
  }
}

.static {
  position:absolute;
  background: white;
}

.static:hover {
  opacity:0;
}

/* The location pointed to by the popup tip. */
.popup-tip-anchor {
  height: 0;
  position: absolute;
  /* The max width of the info window. */
  width: 200px;
}
/* The bubble is anchored above the tip. */
.popup-bubble-anchor {
  position: absolute;
  width: 100%;
  bottom: /* TIP_HEIGHT= */ 8px;
  left: 0;
}
/* Draw the tip. */
.popup-bubble-anchor::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  /* Center the tip horizontally. */
  transform: translate(-50%, 0);
  /* The tip is a https://css-tricks.com/snippets/css/css-triangle/ */
  width: 0;
  height: 0;
  /* The tip is 8px high, and 12px wide. */
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: /* TIP_HEIGHT= */ 8px solid white;
}
/* The popup bubble itself. */
.popup-bubble-content {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -100%);
  /* Style the info window. */
  background-color: white;
  padding: 5px;
  border-radius: 5px;
  font-family: sans-serif;
  overflow-y: auto;
  max-height: 60px;
  box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.5);
}

